import { ProbeArgument } from '../createStore';

import { clearSessionStorage, saveStateToSessionStorage, initApp, setIsUserLoggedIn } from './session.actions';
import { SESSION_STORAGE_KEY } from '../../core/constants';
import moment from 'moment-timezone';

export default function sessionProbe({ onAction }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    if (flowAPI.biLogger) {
      flowAPI.biLogger.initSession({ projectName: undefined } as any);
    }
    dispatch(
      setIsUserLoggedIn({ isLoggedIn: flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor' }),
    );
  });
  onAction(saveStateToSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    const { cart, checkout, addressForm } = getState();
    const { orderItems, coupon, comment } = cart;
    const { checkoutStep, contact } = checkout;
    const { selectedAddressOption } = addressForm;
    const timestamp = moment().valueOf();

    flowAPI.controllerConfig.platformAPIs.storage.session.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        orderItems,
        coupon,
        comment,
        dispatch: checkout.dispatch,
        checkoutStep,
        contact,
        selectedAddressOption,
        timestamp,
      }),
    );
  });
  onAction(clearSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    flowAPI.controllerConfig.platformAPIs.storage.session.removeItem(SESSION_STORAGE_KEY);
  });
}
